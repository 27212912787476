import React from 'react'
import { Vendor, Service } from '@type/vendors'
import { OrgStructure } from '@type/orgstructure'
import {
  CommunicationType,
  CompletionType,
  FieldType,
  IntegrationType,
  Frequency,
  WorkflowType,
  EmailRequestsAvailability,
  StepFlow,
  FormType,
} from '@type/common'
import { Workflow, WorkflowFieldBindings } from '@models/Workflow'
import { NetsuiteSetting } from './types'
import { Integration as IntegrationVendor } from '@type/integration'
import { Feature } from '@src/types/features'

export { FormType }

export { FieldType }

export const enum FieldGroup {
  general = 'general',
  finance = 'finance',
  softledger = 'softledger',
  xero = 'xero',
  quickbooks = 'quickbooks',
}

export type FieldBase = {
  Icon: (props: React.ComponentProps<'svg'>) => JSX.Element
  label: string
  tooltip?: string
  tip?: string
  group?: FieldGroup
  feature?: Feature
}

export type CommonInfo = {
  id?: number
  name: string
  channel: CommunicationType
  slackChannel?: string
  category: Array<number>
  privacy: OrgStructure

  watchers?: Array<string | number>

  description?: string | null
  dataSource: DataSourceType | null
  formType: FormType | null
  workflowType: WorkflowType

  template?: number

  emailRequestsAvailability: EmailRequestsAvailability
  slug?: string

  publicForm: PublicForm

  organization?: number
  integration?: IntegrationVendor
}

export type Template = number | null

export type JiraIntegrationSettings = {
  project?: {
    id: string
    name: string
  }
  issue?: {
    id: string
    name: string
  } | null
  assignee?: {
    accountId: string
    name: string
  } | null
}
export type EmailIntegrationSettings = {
  emails?: string[]
  fields?: string[]
  description?: string | null
}

export type ApiHeader = Record<string, unknown>
export type ApiIntegrationSettings = {
  fields: string[]
  headers: ApiHeader
  url: string
}

export type Integration = {
  vendor: Vendor
  service?: Service
  key?: string
  jira?: JiraIntegrationSettings | null
  email?: EmailIntegrationSettings | null
  api?: ApiIntegrationSettings | null
  netsuiteSetting?: NetsuiteSetting | null
}

export type IntegrationState = {
  [key in IntegrationType]: Integration | null
}

export type FormField = {
  label: string
  customName?: string
  options?: string[]
  columns?: FormState
  type: FieldType
  id: string
  order: number
  required: boolean
  hideInSlack: boolean
  binding?: WorkflowFieldBindings
  reqiredByDefault?: boolean
}
export type FormState = {
  [key: string]: FormField
}

export const enum DataSourceType {
  form = 'form',
  mailbox = 'mailbox',
  integration = 'integration',
  vendorForm = 'vendorForm',
}

export interface PublicForm {
  uuid: string | null
  isAuthorizedOnly: boolean | null
}

export interface DataSource {
  type: DataSourceType | null
  form: FormState
  integration: Integration | null // will be moved to pipelines
  workflowType: WorkflowType
  formType: FormType | null
  publicForm: PublicForm
  isPannelCollapsed: boolean
  formDescription?: string | null
  apiEnabled?: boolean
  lineItemsEditId?: string
  isAddingFieldWhenCollapsed?: string
  editableFieldId?: string | null
}

export const enum ApprovalStepSubject {
  task = 'task',
  step = 'step',
}

export const enum ApprovalStepAction {
  approve = 'approve',
  reject = 'reject',
}

export interface StepConditon {
  rule?: Rule
  value?: number[] | string | string[] | Range
  field: string
}

export interface StepConditons {
  [key: string]: StepConditon
}

export interface Approver {
  id: number
  name: string
  roles: string[]
  channel: CommunicationType[]
}

export interface ApprovalStep {
  id: string
  approvers: Approver[]
  completionType: CompletionType
  type: StepFlow
  order: number
  department?: string
  role?: string[]
  name: string
  description?: string
  subject: ApprovalStepSubject
  actions: {
    [ApprovalStepAction.approve]: string
    [ApprovalStepAction.reject]: string
  }
  customApprovers: CustomApprover[]
  conditionsEnabled?: boolean
  defaultCondition: boolean
  conditions: StepConditons
}

export interface ApprovalStepsState {
  [key: string]: ApprovalStep
}

export interface Range {
  from: string
  to: string
}

export const enum Rule {
  Less = 'Less',
  Range = 'Range',
  Greater = 'Greater',
  Contains = 'Contains',
}

export interface Condition {
  rule: Rule
  value: string | string[] | Range
}

export type Reminder = {
  frequency?: Frequency
  time?: string
}

export interface ApprovalFlowState {
  steps: ApprovalStepsState
  reminder?: Reminder
  reminderEnabled: boolean
  watchers?: Array<string | number>
  watchersEnabled: boolean
  slackChannel?: string
  slackChannelEnabled?: boolean
  notifyAll?: boolean
  excludeRequestorFromApprovers?: boolean
  excludeDuplicateApprovers?: boolean
  selectedStep?: string
  showStepInEmail?: boolean
  apiEnabled: boolean
  signature21CFRPart11Enabled?: boolean
}

export type Value = string | boolean | number | (string | number)[] | undefined

export const enum CustomApprover {
  specifiedByRequestor = 'specified_by_requestor',
  requestor = 'requestor',
  manager = 'manager',
  managerOfManager = 'manager_manager',
  costCenterOwner = 'cost_center_owner',
}

export type GroupedConditions = { [key: string]: { conditions?: StepConditons; steps: ApprovalStepsState } }

export type Draft = {
  commonInfo: CommonInfo
  form: FormState
  approvalFlow: ApprovalFlowState
  integration: IntegrationState
  uuid: string
  index?: number
}

export interface ApprovalForm {
  intakeForm: FormState
  approvalFlow: ApprovalFlowState
  commonInfo: CommonInfo
  integration: IntegrationState
  workflow?: Workflow<IsResponse>
}
